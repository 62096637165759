import { AuthModule } from "@/store/modules/Auth";
import { MessageModule } from "@/store/modules/Message";
import { TicketModule } from "@/store/modules/Ticket";
import { DateTime } from "ts-luxon";
import { AlertModule } from "@/store/modules/Alert";
import { alertMessages } from "@/util/consts";
import { env } from "@/config";
import { IUser } from "@/callcenter/omni";
import axios from "axios";

type ParameterType = "value" | "length";
type OperatorType = "equal" | "gte" | "gt" | "lte" | "lt";

export function executeOperator(
	value1: number,
	value2: number,
	operator: OperatorType
) {
	console.log(value1, operator, value2);
	if (operator === "equal") return value1 === value2;
	if (operator === "gt") return value1 > value2;
	if (operator === "gte") return value1 >= value2;
	if (operator === "lte") return value1 <= value2;
	if (operator === "lt") return value1 < value2;
	return false;
}

export function isValidUrl(str: string) {
	try {
		return Boolean(new URL(str));
	} catch (error) {
		return false;
	}
}

export function mediaToUrl(str: string, basePath = "") {
	if (isValidUrl(str)) return str;
	return `${basePath}/${str}`;
}

export function indexByKeys(obj: any, val: any, ...args: string[]): object {
	const key = args.shift() ?? "";
	obj[key] =
		args.length > 0 ? indexByKeys(obj?.[key] ?? {}, val, ...args) : val;
	return obj;
}
export function indexWithArray(obj: any, val: any, ...args: string[]): object {
	const key = args.shift() ?? "";
	if (args.length > 0) obj[key] = indexByKeys;
	else if (Array.isArray(obj[key])) obj[key].push(val);
	else obj[key] = [val];
	return obj;
}
export function getDateOrUndefined(date: string | undefined) {
	return date ? new Date(date) : undefined;
}
export function rand(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}
type MinMax = { min: number; max: number };
export function getRandomColor(
	hc: MinMax = { min: 0, max: 360 },
	sc: MinMax = { max: 60, min: 30 },
	lc: MinMax = { min: 50, max: 60 }
): string {
	const h = rand(hc.min, hc.max);
	const s = rand(sc.min, sc.max);
	const l = rand(lc.min, lc.max);
	return `hsl( ${h},${s}%,${l}%)`;
}

export function getRelativeTime(iso: string): string {
	const dt = DateTime.fromISO(iso).setLocale("es");
	const diff = Math.abs(dt.diffNow("days").as("days"));
	if (diff > 7) {
		return dt.toFormat("el dd/MM/yyyy");
	} else if (diff > 3) {
		return dt.toFormat("el cccc");
	} else return dt.toRelativeCalendar() ?? "";
}
export function getTypeFile(
	url: string,
	type?: string
): {
	text: string;
	icon?: string;
	type: string;
} {
	const str = type ? url + type : url;
	if (/jpg|jpeg|image/i.test(str))
		return { text: "Imagen", icon: "ni ni-camera-compact", type: "jpg" };
	else if (/png/i.test(str))
		return { text: "Imagen", icon: "ni ni-camera-compact", type: "png" };
	else if (/svg/i.test(str))
		return { text: "Imagen", icon: "ni ni-camera-compact", type: "svg" };
	else if (/\.gif/i.test(str))
		return { text: "Imagen", icon: "ni ni-camera-compact", type: "gif" };
	else if (/\.webp/i.test(str))
		return { text: "Sticker", icon: "ni ni-camera-compact", type: "webp" };
	else if (/mp4|video\/mp4|video/i.test(str) && !/audio/i.test(str))
		return { text: "Video", icon: "fas fa-video", type: "mp4" };
	else if (/wav|mp3|oga/i.test(str) || /audio/i.test(str))
		return { text: "Audio", icon: "fas fa-microphone", type: "mp3" };
	else if (/file|txt|doc|docx|pdf/i.test(str) || /audio/i.test(str))
		return { text: "Archivo", icon: "fas fa-file-alt", type: "file" };
	return { text: "unknown", type: "unknown" };
}

export function firstLetters(str: string) {
	let i = 0;
	return str.split(" ").reduce((prev, curr) => {
		if (!curr.trim().length) return prev;
		return i++ < 2 ? `${prev}${curr[0]?.toLocaleUpperCase()}` : prev;
	}, "");
}
export function getInitials(str: string, strBkp?: string): string {
	const regStr = /[a-z]/i.exec(str);
	if (regStr) return firstLetters(str.substring(regStr.index));
	else if (strBkp) return getInitials(strBkp);
	else return firstLetters(str);
}

export function messageListObserver(
	containerRef: HTMLElement
): IntersectionObserver {
	const options = {
		root: document.getElementById("messages-list"),
		rootMargin: "60px",
		threshold: 0,
	};

	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting)
				switch (entry.target.id) {
					case "infinite-loader-bottom-messages":
						MessageModule.requestMessages({
							token: AuthModule.token ?? "",
							reset: false,
							ticket: TicketModule.currentRoomID ?? "",
							date: MessageModule.lastMessage?.isoDate ?? "",
							bottonLoad: true,
						});
						break;
					case "infinite-loader-messages":
						preventTopScroll(containerRef);
						MessageModule.requestMessages({
							token: AuthModule.token ?? "",
							reset: false,
							ticket: TicketModule.currentRoomID ?? "",
							date: MessageModule.firstMessage?.isoDate ?? "",
						});
						break;
					default:
						break;
				}
		});
	}, options);

	return observer;
}

export function flatObject(obj: object, key?: string): object {
	return Object.entries(obj).reduce((prev, [k, v]) => {
		if (typeof v === "object" && v !== null)
			return Object.assign(prev, flatObject(v, key ? `${key}.${k}` : k));
		prev[key ? `${key}.${k}` : k] = v;
		return prev;
	}, {} as { [k: string]: any });
}

function preventTopScroll(containerRef: HTMLElement): void {
	const container = containerRef;
	const prevScrollHeight = container.scrollHeight;

	const observer = new ResizeObserver(() => {
		if (container.scrollHeight !== prevScrollHeight) {
			if (containerRef) {
				containerRef.scrollTo({
					top: container.scrollHeight - prevScrollHeight,
				});
				observer.disconnect();
			}
		}
	});

	for (let i = 0; i < container.children.length; i++) {
		observer.observe(container.children[i]);
	}
}
export function validateTipification(
	tipification: string | undefined,
	subTipification: string | undefined
) {
	console.log("sac", tipification && subTipification);
	if (tipification == "" || subTipification == "") {
		AlertModule.SOCKET_PUSH_NOTIFICATION({
			text: alertMessages.ERROR_TYPIFICATION,
			type: "error",
		});
		throw new Error("Error al validar las tipificaciones");
	}
}

export const apiPetitions = {
	createCallHistory: env.DynamicTypification
		? `callhistory/create/dynamic`
		: `callhistory/create`,
	callHistory: env.DynamicTypification
		? `/callhistory/find/dynamic`
		: `/callhistory/find`,
	updateCallHistory: `/callhistory/update/dynamic`,
};
export function putFirstLetterInUpperCase(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

// Funcion que realiza validación en base a parámetros y operadores
export function matchRule(
	target: { target: string | number; parameter: ParameterType },
	operator: OperatorType,
	value: string | number
) {
	const value1 = target.target;
	const value2 = typeof value === "string" ? parseInt(value) : value;
	try {
		return target.parameter === "value"
			? executeOperator(value1 as number, value2, operator)
			: executeOperator(value1.toString().length, value2, operator);
	} catch (error) {
		console.log(error);
		return false;
	}
}
//Funcion que limpia todos los caracteres que no sean numeros.
export function cleanPhoneInput(phoneNumber: string): string {
	phoneNumber = phoneNumber.replace(/\D/g, "");
	return phoneNumber;
}

export function removeExitDigits(number: string) {
	if (number.length > 5) {
		env.ExitDigits.forEach(e => {
			if (number[0] == e) {
				number = number.substring(1);
			}
		});
	}

	return number;
}
export function capitalizeFirst(str: string) {
	if (str === "") return "";
	const lowStr = str.toLowerCase();
	return lowStr.charAt(0).toUpperCase() + lowStr.slice(1);
}

export async function fetchRemoteDataClient(
	callNumber: string
): Promise<IUser | null> {
	if (env.remoteClientURL) {
		try {
			const clientInfo = await axios.post(env.remoteClientURL, {
				phone: callNumber,
			});
			return Object.keys(clientInfo.data).length
				? (clientInfo.data as IUser)
				: null;
		} catch (error) {
			return null;
		}
	}
	return null;
}
