import { Field, SelectProps } from "@/types/components";
import Select from "@/components/Inputs/SelectControlled.vue";
import ArgonCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import ArgonDatePicker from "@/components/Inputs/ArgonDatePicker.vue";
import ArgonInput from "@/components/Inputs/BaseInput.vue";
import { ref } from "vue";
import useFormData from "@/composables/FormDataComposable";
import { getDateOrUndefined, matchRule } from "@/util/utils";
import { UploadProps, UploadUserFile } from "element-plus";
import { env } from "@/config";

export interface ArgonUploadProps extends UploadProps {
	tip: string;
	indication: string;
	defaultValue: UploadUserFile;
	url?: string;
}
export default function () {
	// types
	type FormPayload = {
		[k: string]: any;
	};

	const { formsValues } = useFormData();

	// state
	const state = ref<FormPayload>({});

	function getComponent(type: Field["type"]) {
		switch (type) {
			case "options":
				return Select;
			case "checkbox":
				return ArgonCheckbox;
			case "text":
			case "number":
			case "textarea":
				return ArgonInput;
			default:
				return ArgonDatePicker;
		}
	}

	function getProps(input: Field, updt: { [k: string]: any }) {
		switch (input.type) {
			case "options":
				return {
					value: input.value,
					label: input.label,
					placeholder: input.placeholder,
					name: input.name,
					parent: input.depends_on?.name
						? updt[input.depends_on.name]
							? updt[input.depends_on.name]
							: null
						: undefined,
					remote: input.remote,
					localSearch: input.localSearch,
					fetch: input.url,
					fetchConfig: input.authenticate
						? {
								auth: {
									username: env.OmniUsernameAgent,
									password: env.OmniPasswordAgent,
								},
						  }
						: undefined,
					query_param: input.query_param,
					url_param: input.depends_on?.url_param,
					options: input.staticOptions,
					disabled: input.disabled,
					inputGroupClasses: "input-group",
					select: e => {
						state.value = {
							...state.value,
							[input.name]: Array.isArray(e) ? e.join(",") : e,
						};
					},
					selectOptions: {
						noDataText: "Sin coincidencias",
						size: input.size ?? "default",
						valueKey: input.options?.key ?? "",
						labelKey: input.options?.label ?? "",
						returnAll: input.options?.returnAll ?? false,
						multiple: input.multiple ?? false,
					},
					params: input.depends_on
						? input.depends_on.query_param
							? {
									[input.depends_on.query_param]:
										updt[input.depends_on.name]?.[input.options?.key ?? ""],
							  }
							: input.depends_on.url_param
							? {
									[input.depends_on.url_param]:
										updt[input.depends_on.name]?.[input.depends_on.url_param],
							  }
							: undefined
						: undefined,
				} as SelectProps;
			case "checkbox":
				return {
					id: `checkbox-${input.name}`,
					name: input.name,
					label: input.label,
					modelValue: input.value ?? false,
					disabled: input.disabled ?? false,
					"group-classes":
						input.classParent ?? "d-flex align-items-center h-100 mb-4",
					"label-classes": "text-muted",
				};
			case "text":
			case "number":
			case "textarea": {
				const { hidden: _, ...cleanInput } = input;
				return cleanInput;
			}
			default: {
				const value = getDateOrUndefined(input.value as string);
				return {
					type: input.type,
					"default-value": value,
					label: input.label,
					classPicker: input.classPicker,
					classParent: input.classParent,
					format: input.format ?? "DD/MM/YYYY",
					valueChange: value ? value.getTime() : 0,
					placeholder: input.placeholder,
				};
			}
		}
	}

	function hiddenInput(
		name: string,
		type: string,
		params: { [k: string]: string | string[] | boolean | boolean[] },
		data: { [k: string]: string | object },
		setField: (input: string, value: any) => any
	): boolean {
		const hidden: boolean[] = [];
		Object.keys(params).forEach(p => {
			const value = data[p]
				? typeof data[p] !== "object"
					? data[p]
					: (data[p] as any).value
				: undefined;
			if (Array.isArray(params[p]))
				hidden.push(!(params[p] as any[]).includes(value));
			else if (/^\[.*\]$/.test(params[p].toString())) {
				const [_, rule] = /^\[(.*)\]$/.exec(
					params[p].toString()
				) as RegExpExecArray;
				if (rule) {
					const [parameter, operator, condition] = rule.split(":");
					hidden.push(
						!matchRule(
							{
								target: value ?? "",
								parameter: parameter as any,
							},
							operator as any,
							condition
						)
					);
				} else hidden.push(false);
			} else hidden.push(params[p] !== value);
		});
		console.log(hidden);
		const hide = hidden.includes(true);
		if (hide)
			if (type === "number") setField(name, null);
			else setField(name, "");
		return hide;
	}

	function isHidden(
		input: Field,
		userFormValues: { [k: string]: string | object },
		setField: (input: string, value: any) => any
	): boolean {
		const values = userFormValues;
		if (input.include)
			Object.assign(values, formsValues.value[input.include as any] ?? {});
		return !!(
			input.hidden &&
			hiddenInput(input.name, input.type, input.hidden, values, setField)
		);
	}

	return {
		hiddenInput,
		isHidden,
		getProps,
		getComponent,
		state,
	};
}
