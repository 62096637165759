import { CallDataModule } from "@/store/modules/CallData";
import { TicketModule } from "@/store/modules/Ticket";
import { TypificationModalModule } from "@/store/modules/TypificationModal";
import { AppModule } from "@/store/modules/app";
import { IndexType } from "@/types/store";
import { computed } from "vue";

export default function () {
	const formsValues = computed<IndexType<any>>(() => ({
		dynamic_user:
			TicketModule.currentTicket?.client?.[0].dynamicData ??
			CallDataModule.user?.dynamicData,
		dynamic_typification:
			TypificationModalModule.omniData ?? TypificationModalModule.data,
		omnichannel_config: AppModule.userConfig,
	}));
	return {
		formsValues,
	};
}
