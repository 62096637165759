import { createStore } from "vuex";
import { IApp } from "./modules/app";
import { IAuth } from "./modules/Auth";
import { IPause } from "./modules/Pause";
import { IAccount } from "./modules/Account";
import { ITypification } from "./modules/Typification";
import { IMessage } from "@/types/messages";
import { Iindicator } from "./modules/Indicators";
import { IAlertModule } from "@/store/modules/Alert";
import { ICallData } from "@/types/call";
import { Notification } from "@/store/modules/Notification";
import { IControl } from "@/types/control";
import { Files } from "@/store/modules/Files";
import { apiEnable } from "@/conection";
import { IHistory } from "@/store/modules/History";
import { IPhone } from "@/store/modules/Phone";
import { ITicket } from "@/types/tickets";
import { Conversation } from "./modules/Conversation";
import { Identification } from "./modules/Identification";
import { IOutputDevice } from "./modules/OutputDevice";

export interface IRootState {
	app: IApp;
	auth: IAuth;
	alert: IAlertModule;
	notification: Notification;
	pause: IPause;
	control: IControl;
	ticket: ITicket;
	account: IAccount;
	typification: ITypification;
	message: IMessage;
	indicators: Iindicator;
	calldata: ICallData;
	files: Files;
	history: IHistory;
	phone: IPhone;
	conversation: Conversation;
	identification: Identification;
	outputDevice: IOutputDevice;
}

export const store = createStore<IRootState>({
	plugins: [apiEnable],
});
