import {
	getModule,
	Module,
	Mutation,
	VuexModule,
} from "vuex-module-decorators";
import { store } from "@/store";

export enum actionType {
	create = "create",
	edit = "edit",
}

export interface ITypificationModal {
	data: { [k: string]: string | undefined };
	options: { [k: string]: object };
	type: actionType;
	idSql: number | null;
	idMongo: string | null;
}

@Module({ dynamic: true, store, namespaced: true, name: "typificationModal" })
export class TypificationModal
	extends VuexModule
	implements ITypificationModal {
	_data = {
		omnichannel: {},
		callCenter: {},
	};
	_options = {};
	_type = actionType.create;
	_idSql: number | null = null;
	_idMongo: string | null = null;
	_alert: string | null = null;

	get data() {
		return this._data.callCenter;
	}

	get omniData() {
		return this._data.omnichannel;
	}

	get options() {
		return this._options;
	}

	get type() {
		return this._type;
	}

	get idSql() {
		return this._idSql;
	}

	get idMongo() {
		return this._idMongo;
	}

	get alert() {
		return this._alert;
	}

	@Mutation
	setData(data: { [k: string]: string | undefined }) {
		console.log("Setting data: ", data)
		this._data.callCenter = data;
	}

	@Mutation
	setOmniData(data: { [k: string]: string | undefined }) {
		this._data.omnichannel = data;
	}

	@Mutation
	setOptions(options: { [k: string]: object }) {
		this._options = options;
	}

	@Mutation
	setType(type: actionType) {
		this._type = type;
	}

	@Mutation
	setIdSql(id: number | null) {
		this._idSql = id;
	}

	@Mutation
	setIdMongo(id: string | null) {
		this._idMongo = id;
	}
	@Mutation
	setAlert(payload: string | null) {
		this._alert = payload;
	}
}

export const TypificationModalModule = getModule(TypificationModal);
